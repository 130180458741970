import TrackingUtils from './tracking/tracking.utils';

const onNewstickerClickHandler = async (event: Event): Promise<void> => {
  const target = event.currentTarget as HTMLAnchorElement;
  const pageElementName = target.getAttribute('data-event-label') || 'n/a';
  const pageContentId = target.getAttribute('data-share-pagecontentid') || 'n/a';

  TrackingUtils.trackSnowplowEvent({
    pageElementName,
    pageElementType: 'newsticker',
    pageElementId: pageContentId,
    eventName: 'pageElement.click',
    eventCategory: 'pageElement',
    eventAction: 'pageElement.clickInternal',
    eventLabel: 'n/a',
  });
};

export const initNewstickerButtons = (): void => {
  const newstickerButtons = document.querySelectorAll('#newstickerButton');
  if (newstickerButtons) {
    for (const moreButton of newstickerButtons) {
      moreButton.addEventListener('click', onNewstickerClickHandler);
    }
  }
};
